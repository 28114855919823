<script>
export default {
  methods: {
    openAddNewToolModal() {
      this.$eventHub.$emit("open-add-new-tool-modal", true);
      this.$eventHub.$emit("open-all-my-apps-modal", false);
    }
  }
};
</script>
<template>
  <div class="add-tool-card__container" @click="openAddNewToolModal">
    <img
      class="add-tool-card__icon"
      src="../../assets/img/icons/add.svg"
      alt="agregar"
    />
    <p class="add-tool-card__text">Agregar <br />herramienta</p>
  </div>
</template>
<style src="../../assets/css/components/add-tool-card.css"></style>
