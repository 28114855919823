<script>
import AppCard from "./AppCard.vue";
import AddToolCard from "./AddToolCard.vue";
import { ordenarJson } from "@/assets/js/general.js";
import SelectClientPopup from "../SelectClientPopup.vue";

export default {
  components: { AppCard, AddToolCard, SelectClientPopup },
  data() {
    return {
      isOpen: false,
      myStoreToken: false,
      configuration: {},
      userIsPerformance: false,
      goToModule: null,
      goToTitle: null
    };
  },
  mounted() {
    this.getConfiguration();
    this.getMITiendaBASFURL();
    this.$eventHub.$on("recorrido-listo", () => this.getConfiguration());
    this.$eventHub.$on("herramienta-nueva", herramienta =>
      this.newTool(herramienta)
    );
    this.$eventHub.$on("eliminar-herramienta", herramienta => {
      this.removeTool(herramienta);
      this.$eventHub.$emit("delete-tool-modal-open", false);
    });
    this.setUserIsPerformance();
    // Escuchar el evento para abrir el modal
    this.$eventHub.$on("open-all-my-apps-modal", shouldOpen => {
      this.isOpen = shouldOpen;
    });
    this.$eventHub.$on("update", () => {
      this.setUserIsPerformance();
      this.$forceUpdate();
    });
  },
  updated() {
    if (this.isOpen) {
      document.body.classList.add("body-no-scroll");
    }
  },
  methods: {
    closeModal() {
      this.isOpen = false;
      // Elimina la clase para habilitar nuevamente el scroll
      document.body.classList.remove("body-no-scroll");
    },
    setUserIsPerformance() {
      if (
        (this.$usuario.rolClientes != null &&
          this.$usuario.rolClientes.detalle == "Performance") ||
        (this.$usuario.rolCYO != null &&
          this.$usuario.rolCYO.detalle == "Performance") ||
        (this.$usuario.rolPOG != null &&
          this.$usuario.rolPOG.detalle == "Performance") ||
        (this.$usuario.rolCC != null &&
          this.$usuario.rolCC.detalle == "Performance")
      ) {
        this.userIsPerformance = true;
      } else {
        this.userIsPerformance = false;
      }
    },
    getMITiendaBASFURL() {
      const that = this;
      this.$axios
        .get(this.$localurl + "/api/mitienda")
        .then(function(response) {
          that.myStoreToken = response.data;
        })
        .catch(function() {});
    },
    openAddNewToolModal() {
      this.$eventHub.$emit("open-add-new-tool-modal", true);
      document.body.classList.add("body-no-scroll");
    }, // Getting the configuration from the server.
    newTool(tool) {
      if (this.configuration.herramientas.some(h => h.id == tool.id)) {
        this.configuration.herramientas = this.configuration.herramientas.filter(
          h => h.id != tool.id
        );
      }
      this.configuration.herramientas.push(tool);
      this.saveSettings();
    }, // Making a PUT request to the server and sending the data in the configuracion object.
    saveSettings() {
      const that = this;
      this.$axios
        .put(this.$localurl + "/api/configuracion", this.configuration)
        .then(function(response) {
          that.configuration = {};
          that.$nextTick(() => {
            that.configuration = response.data;
          });
          this.getConfiguration();
        })
        .catch(function(response) {
          console.log(response);
        });
    },
    postMetric(module) {
      this.$axios
        .post(this.$localurl + "/api/metrica/" + module)
        .then(function(response) {
          console.log(response);
        })
        .catch(function(response) {
          console.log(response);
        });
    },
    checkGoTo(text) {
      this.goToModule = text;
      switch (text) {
        case "cc":
          if (this.$usuario.email.toLowerCase().includes("basf.com")) {
            this.goTo(text);
          } else {
            this.goToTitle = "cuenta corriente";
            this.$refs.selectClientPopup.open();
          }
          break;
        case "cyo":
          if (this.$usuario.email.toLowerCase().includes("basf.com")) {
            this.goTo(text);
          } else {
            const that = this;
            this.$axios
              .get(this.$localurl + "/api/cyo/muchos-clientes")
              .then(function() {
                that.goTo(text);
              })
              .catch(function() {
                that.goToTitle = "cuenta y orden";
                that.$refs.selectClientPopup.open();
              });
          }
          break;
        default:
          break;
      }
    },
    goTo(text) {
      this.postMetric(text);
      const that = this;
      let token = this.$cookies.get("token-landing");
      if (token == null) {
        token = localStorage.getItem("token-landing");
      }
      let ruta = "";
      switch (text) {
        case "mialta":
          ruta = this.$mialta_url + "/landing/" + token;
          break;
        case "cyo":
          ruta = this.$cyo_url + "/landing/" + token;
          break;
        case "mispedidos":
          ruta = this.$mispedidos_url + "/landing?auth=" + token;
          break;
        case "cc":
          if (this.$dev) {
            ruta =
              "https://dev.micuenta.basf.com.ar/cuenta-corriente/landing/" +
              token;
          } else {
            ruta =
              "https://micuenta.agro.basf.com/cuenta-corriente/landing/" +
              token;
          }
          break;
        case "pog":
          ruta = this.$pog_url + "/landing/" + token;
          break;
        case "simulador":
          ruta = this.$dev
            ? "https://dev.micuenta.basf.com.ar/simulador"
            : "https://micuenta.agro.basf.com/simulador";
          if (this.$usuario.email.toLowerCase().includes("basf.com")) {
            ruta =
              ruta +
              "/plancomercial/planificacion?auth=" +
              token +
              "&admin=true";
          } else ruta = ruta + "/landing?auth=" + token;
          break;
        case "documentos":
          if (this.$dev) {
            ruta =
              "https://dev.micuenta.basf.com.ar/misdocumentos/token/" + token;
          } else {
            ruta =
              "https://micuenta.agro.basf.com/misdocumentos/token/" + token;
          }
          break;
        case "mitienda":
          window
            .open(
              "https://mitiendabasf.com.ar?token=" + this.myStoreToken,
              "_blank"
            )
            .focus();
          break;
        case "weboficial":
          window
            .open("https://agriculture.basf.com/ar/es.html", "_blank")
            .focus();
          break;
        default:
          break;
      }
      if (ruta) {
        this.mostrarLanding = false;
        setTimeout(function() {
          that.mostrarImagen = true;
          setTimeout(function() {
            that.mostrarListo = true;
            window.location.href = ruta;
          }, 700);
        }, 800);
      }
    },
    goToMetric() {
      this.$router.push("/metricas");
    },
    removeTool(tool) {
      this.configuration.herramientas = this.configuration.herramientas.filter(
        h => h.id != tool.id
      );
      this.saveSettings();
    },
    gotoPermissions() {
      this.$router.push("/permisos");
    },
    getConfiguration() {
      const that = this;
      this.$axios
        .get(this.$localurl + "/api/configuracion")
        .then(function(response) {
          if (response.data != "") {
            that.configuration = response.data;
            that.configuration.herramientas = that.sortJson(
              that.configuration.herramientas,
              "nombre",
              true
            );
          }
        })
        .catch(function(response) {
          console.log(response);
        });
    },
    gotoTool(link) {
      console.log(link);
    },
    sortJson: function(json, prop, asc) {
      return ordenarJson(json, prop, asc);
    },
    soldToPopupCallback() {
      this.goTo(this.goToModule);
    }
  }
};
</script>

<template>
  <transition name="fade-fast">
    <div v-if="isOpen">
      <div class="all-my-apps-modal__background" @click="closeModal"></div>
      <div class="all-my-apps-modal__container">
        <div class="all-my-apps-modal__header">
          <p class="all-my-apps-modal__title">Todas Mis Apps</p>
          <img
            class="all-my-apps-modal__close-icon"
            src="../../assets/img/landing/close.svg"
            alt="close"
            @click="closeModal"
          />
        </div>
        <div class="all-my-apps-modal__cards">
          <div
            class="option-card permisos-card"
            v-if="userIsPerformance"
            @click="gotoPermissions"
          >
            <img
              class="icon_lg"
              src="../../assets/img/icons/permisos_icon.png"
              alt="Permisos"
            />
            <p>Permisos</p>
          </div>

          <div
            v-if="$usuario.rolCYO != null"
            class="option-card micyo-card"
            @click="checkGoTo('cyo')"
          >
            <img
              class="icon_lg"
              src="../../assets/img/icons/micyo_icon_white.png"
              alt="mi cuenta & Orden"
            />
            <p>Mi Cuenta<br />& Orden</p>
          </div>

          <div
            v-if="$usuario.rolCC != null"
            class="option-card micc-card"
            @click="checkGoTo('cc')"
          >
            <img
              class="icon_lg"
              src="../../assets/img/icons/micc_icon_white.png"
              alt="mi cuenta corriente"
            />
            <p>Mi Cuenta<br />Corriente</p>
          </div>

          <div
            v-if="$usuario.rolPedidos != null"
            class="option-card misimulador-card"
            @click="goTo('mispedidos')"
          >
            <img
              class="icon_lg"
              src="../../assets/img/landing/mis-pedidos.png"
              alt="Mis Pedidos"
            />
            <p>Mis<br />Pedidos</p>
          </div>

          <div
            v-if="$usuario.rolPOG != null"
            class="option-card mipog-card"
            @click="goTo('pog')"
          >
            <img
              class="icon_lg"
              src="../../assets/img/icons/mipog_icon_white.png"
              alt="POG"
            />
            <p>Mi<br />POG</p>
          </div>

          <div
            class="option-card misimulador-card"
            v-if="$usuario.rolPlanComercial != null"
            @click="goTo('simulador')"
          >
            <img
              class="icon_lg"
              src="../../assets/img/icons/paper.svg"
              alt="Mi Plan Comercial"
            />
            <p>Mi Plan<br />Comercial</p>
          </div>

          <div
            class="option-card mi-tienda-card"
            v-if="myStoreToken"
            @click="goTo('mitienda')"
          >
            <img
              class="icon_lg"
              src="../../assets/img/icons/shop.svg"
              alt="Mi tienda"
            />
            <p>Mi Tienda</p>
          </div>

          <div class="option-card web-oficial-card" @click="goTo('weboficial')">
            <img
              class="icon_lg"
              src="../../assets/img/icons/world.svg"
              alt="Web oficial"
            />
            <p>Web Oficial</p>
          </div>

          <div
            class="option-card metricas-card"
            v-if="userIsPerformance"
            @click="goToMetric"
          >
            <img
              class="icon_lg"
              src="../../assets/img/icons/metricas_icon.png"
              alt="Metricas"
            />
            <p>Métricas</p>
          </div>

          <AppCard
            v-for="tool in configuration.herramientas"
            :herramienta="tool"
            :key="tool.id"
            @click="gotoTool(tool.link)"
          />

          <AddToolCard />
        </div>
      </div>
      <SelectClientPopup
        ref="selectClientPopup"
        :title="goToTitle"
        :modulo="goToModule"
        :callback="soldToPopupCallback"
      ></SelectClientPopup>
    </div>
  </transition>
</template>
<style src="../../assets/css/components/all-my-apps-modal.css"></style>
