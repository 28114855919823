<template>
  <transition name="fade-fast">
    <div v-show="mostrar" class="app-popup-container">
      <div class="app-popup-mask" @click="cerrar()"></div>
      <div class="novedad">
        <label class="novedad-title">Publicar video</label>
        <div class="novedad-body">
          <div>
            <div class="novedad-row">
              <label style="visibility: hidden">Titulo del novedad</label>
              <div
                class="novedad-imagen-grande"
                @click="cambiarImagen()"
                :class="{ 'error-input': errorImagen }"
              >
                <img src="../assets/img/icons/add_imagen.png" />
                <label v-show="imagen == null">Agregar imágen</label>
                <label v-show="imagen != null">{{ nombreImagen }}</label>
              </div>
              <input
                class="app-hide"
                type="file"
                ref="imagenUpload"
                @change="changeImagen()"
                accept="image/png, image/jpeg"
              />
            </div>
            <div class="novedad-row">
              <label>Link al video</label>
              <input
                type="text"
                placeholder="Pegar link de enlace acá"
                v-model="link"
                :class="{ 'error-input': errorLink }"
              />
            </div>
            <div class="novedad-row">
              <label>Titulo del anuncio
                <span :class="tituloLongitudRestante > 0 ? 'novedad-longitud' : 'novedad-longitud-error'">{{tituloLongitudRestante}} Caracteres</span>
              </label>
              <input
                type="text"
                placeholder="Escribe título"
                v-model="titulo"
                :class="{ 'error-input': errorTitulo }"
              />
            </div>
            <div class="novedad-row">
              <label>Descripción
                <span :class="descripcionLongitudRestante > 0 ? 'novedad-longitud' : 'novedad-longitud-error'">{{descripcionLongitudRestante}} Caracteres</span>
              </label>
              <textarea
                type="text"
                placeholder="Escribe una breve descripción"
                v-model="descripcion"
                :class="{ 'error-input': errorDescripcion }"
              ></textarea>
            </div>
            <div class="novedad-row">
              <label>Material adicional</label>
              <div class="novedad-file" @click="openFile()">
                <img src="../assets/img/icons/upload2.svg">
                <label>{{files.length === 0 ? 'Selecciona de PC' : files[0].nombre}}</label>
                <img v-if="files.length > 0" class="novedad-file-close" @click.prevent="eliminarFile($event)" src="../assets/img/icons/close.png">
                <input
                  class="app-hide"
                  type="file"
                  placeholder="Selecciona de PC"
                  ref="fileUpload"
                  @change="changeFile()"
                />
              </div>
            </div>
          </div>
          <div>
            <img
              class="novedad-cerrar"
              src="../assets/img/icons/close.png"
              @click="cerrar()"
            />
            <div class="novedad-row">
              <label>Visibilidad</label>
              <Dropdown
                :options="roles"
                :placeholder="'Todos'"
                :paraArriba="false"
                :name="'detalle'"
                :conInput="true"
                v-on:updateOption="updateDropdownRoles"
              ></Dropdown>
            </div>
            <div class="novedad-fijar">
              <div class="novedad-fijar-img">
                <img src="../assets/img/botones/info.png" />
                <div class="novedad-fijar-info">
                  <label>Se mostrará arriba en la grilla de novedades</label>
                </div>
              </div>
              <label class="switch">
                Fijar publicación
                <input type="checkbox" v-model="fijar" />
                <span class="slider round"></span>
              </label>
            </div>
            <div class="novedad-row">
              <label>Vista previa</label>
              <div class="novedad-previa">
                <div class="novedad-previa-imagen-container">
                  <img
                    v-show="imagen != null"
                    class="novedad-previa-imagen-img"
                    ref="imagen"
                  />
                  <img
                    v-show="imagen == null"
                    class="novedad-previa-imagen-icon"
                    src="../assets/img/icons/imagen.png"
                  />
                </div>
                <label class="novedad-previa-titulo">{{
                  titulo != "" ? titulo : "Título"
                }}</label>
                <label class="novedad-previa-descripcion">{{
                  descripcion != "" ? descripcion : "Descripción"
                }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="novedad-btn-container">
          <button class="novedad-btn" @click="guardar()">Listo</button>
        </div>
      </div>
      <Loading v-show="mostrarLoading"></Loading>
    </div>
  </transition>
</template>

<script>
import Dropdown from "@/components/Dropdown.vue";
import Loading from "@/components/Loading.vue";

export default {
  name: "InstructivoPopup",
  components: { Dropdown, Loading },
  data() {
    return {
      idElegido: null,
      mostrar: false,
      roles: [
        {
          detalle: "Distribuidor - Dueño/Gerente/Director",
        },
        {
          detalle: "Distribuidor - Administrativo",
        },
        {
          detalle: "Distribuidor - Vendedor",
        },
        {
          detalle: "BASF",
        },
        {
          detalle: "Todos",
        },
      ],
      rolElegido: null,
      titulo: "",
      tituloMax: 255,
      descripcion: "",
      descripcionMax: 255,
      imagen: null,
      nombreImagen: "",
      errorImagen: false,
      errorTitulo: false,
      errorDescripcion: false,
      errorLink: false,
      fijar: false,
      link: null,
      files: [],
      mostrarLoading: false
    };
  },
  created() {},
  mounted() {
    this.$eventHub.$on("instructivo-abrir", () => (this.mostrar = true));
    this.$eventHub.$on("instructivo-editar", (novedad) => this.editar(novedad));
  },
  computed: {
    descripcionLongitudRestante() {
      return this.descripcionMax - this.descripcion.length
    },
    tituloLongitudRestante() {
      return this.tituloMax - this.titulo.length
    }
  },
  methods: {
    cerrar() {
      this.mostrar = false;
      this.imagen = null;
      this.nombreImagen = "";
      this.titulo = "";
      this.descripcion = "";
      this.link = "";
      this.errorImagen = false;
      this.errorTitulo = false;
      this.errorDescripcion = false;
      this.errorLink = false;
      this.idElegido = null;
      this.rolElegido = null;
      this.$refs.imagen.src = "";
      this.$refs.imagenUpload.value = '';
      this.$refs.fileUpload.value = '';
      this.fijar = false;
      this.files = [];
    },
    editar(novedad) {
      this.imagen = novedad.imagen;
      this.nombreImagen = novedad.imagenNombre;
      this.titulo = novedad.titulo;
      this.descripcion = novedad.descripcion;
      this.link = novedad.link;
      this.idElegido = novedad.id;
      this.fijar = novedad.fijar;
      this.mostrar = true;
    },
    updateDropdownRoles(payload) {
      if (payload.detalle != "Todos") {
        this.rolElegido = payload.detalle;
      } else {
        this.rolElegido = null;
      }
    },
    cambiarImagen() {
      this.$refs.imagenUpload.click();
    },
    changeImagen() {
      const that = this;
      let reader = new FileReader();

      // Create a preview once image has loaded
      reader.onload = function (e) {
        that.reduceImageSize();
        that.$nextTick(() => {
          that.$refs.imagen.src = e.target.result;
        });
      };
      reader.readAsDataURL(this.$refs.imagenUpload.files[0]);
    },
    reduceImageSize() {
      // Read in file
      let file = this.$refs.imagenUpload.files[0];
      this.nombreImagen = file.name;
      const that = this;
      // Ensure it's an image
      if (file.type.match(/image.*/)) {
        console.log("An image has been loaded");

        // Load the image
        let reader = new FileReader();
        reader.onload = function (readerEvent) {
          let image = new Image();
          image.onload = function () {
            // Resize the image
            let canvas = document.createElement("canvas"),
              max_size = 500, // TODO : pull max size from a site config
              width = image.width,
              height = image.height;
            if (width > height) {
              if (width > max_size) {
                height *= max_size / width;
                width = max_size;
              }
            } else {
              if (height > max_size) {
                width *= max_size / height;
                height = max_size;
              }
            }
            canvas.width = width;
            canvas.height = height;
            canvas.getContext("2d").drawImage(image, 0, 0, width, height);
            let dataUrl = canvas.toDataURL("image/jpeg");
            that.imagen = dataUrl.replace(
              /^data:image\/(png|jpg|jpeg);base64,/,
              ""
            );
          };
          image.src = readerEvent.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    openFile() {
      this.$refs.fileUpload.click();
    },
    changeFile(){
      const that = this;
      let reader = new FileReader();

      // Create a preview once image has loaded
      reader.onload = function() {
        const file = that.$refs.fileUpload.files[0];
        that.files.push({
          file: file,
          nombre: file.name
        })
      };
      reader.readAsDataURL(this.$refs.fileUpload.files[0]);
    },
    eliminarFile(e) {
      e.stopPropagation();
      this.files = [];
    },
    guardar() {
      let continuar = true;

      if (this.imagen == null) {
        this.errorImagen = true;
        continuar = false;
      } else {
        this.errorImagen = false;
      }

      if (this.titulo == "" || this.tituloLongitudRestante < 0) {
        this.errorTitulo = true;
        continuar = false;
      } else {
        this.errorTitulo = false;
      }

      if (this.descripcion == "" || this.descripcionLongitudRestante < 0) {
        this.errorDescripcion = true;
        continuar = false;
      } else {
        this.errorDescripcion = false;
      }

      if (this.link == "") {
        this.errorLink = true;
        continuar = false;
      } else {
        this.errorLink = false;
      }

      if (continuar) {
        let that = this;
        this.mostrarLoading = false;
        
        let formData = new FormData();
        this.files.forEach((a) => {
          formData.append("file", a.file);
        });
        this.$axios
          .put(this.$localurl + "/api/file/Materiales", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function(response) {
            let json = {
              id: that.idElegido,
              imagen: that.imagen,
              imagenNombre: that.nombreImagen,
              titulo: that.titulo,
              descripcion: that.descripcion,
              link: that.link,
              rol: that.rolElegido,
              fijar: that.fijar,
              materiales: response.data
            };
            that.$axios.put(that.$localurl + "/api/instructivo", json)
            .then(() => {
              that.mostrarLoading = false;
              that.$eventHub.$emit("instructivo-nuevo");
              that.cerrar();
            });
          })
          .catch(function(response) {
            console.log(response);
            that.mostrarLoading = false;
            that.$eventHub.$emit("snackbar", "Error en la carga, reintente");
          });
      }
    },

  },
};
</script>

<style scoped src="../assets/css/components/novedadpopup.css"></style>