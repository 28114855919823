<script>
export default {
  props: {
    herramienta: Object
  },
  data() {
    return {
      isOpen: false
    };
  },
  mounted() {
    console.log("delete-tool-modal-herramienta", this.herramienta);
    this.$eventHub.$on("delete-tool-modal-open", shouldOpen => {
      this.isOpen = shouldOpen;
    });
  },
  methods: {
    handleClose() {
      this.$eventHub.$emit("delete-tool-modal-open", false);
    },
    handleDelete() {
      this.$eventHub.$emit("eliminar-herramienta", this.herramienta);
    }
  }
};
</script>
<template>
  <transition name="fade-fast">
    <div class="delete-tool-modal__container" v-if="isOpen">
      <div class="delete-tool-modal__header">
        <p>Eliminar</p>
        <p @click="handleClose">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <mask
              id="mask0_7345_505"
              style="mask-type:alpha"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="16"
              height="16"
            >
              <rect width="16" height="16" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_7345_505)">
              <path
                d="M4.26683 12.6673L3.3335 11.734L7.06683 8.00065L3.3335 4.26732L4.26683 3.33398L8.00016 7.06732L11.7335 3.33398L12.6668 4.26732L8.9335 8.00065L12.6668 11.734L11.7335 12.6673L8.00016 8.93398L4.26683 12.6673Z"
                fill="#1C1B1F"
              />
            </g>
          </svg>
        </p>
      </div>
      <p>¿Estás seguro de eliminar la herramienta?</p>
      <div class="delete-tool-modal__footer">
        <button @click="handleClose">Cancelar</button>
        <button class="delete-tool-modal__delete-button" @click="handleDelete">
          Eliminar
        </button>
      </div>
    </div>
  </transition>
</template>

<style src="../../assets/css/components/delete-tool-modal.css"></style>
