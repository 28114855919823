import { render, staticRenderFns } from "./NewPOG.vue?vue&type=template&id=ee05d08e&scoped=true"
import script from "./NewPOG.vue?vue&type=script&lang=js"
export * from "./NewPOG.vue?vue&type=script&lang=js"
import style0 from "../../assets/css/components/new-pog.css?vue&type=style&index=0&id=ee05d08e&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ee05d08e",
  null
  
)

export default component.exports