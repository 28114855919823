<script>
export default {
  name: "ModifyYourProfileCard",
  data() {
    return {
      show: false,
      title: "",
      description: ""
    };
  },
  mounted() {
    this.$eventHub.$on("update", () => this.checkUser());
  },
  methods: {
    ir() {
      let token = this.$cookies.get("token-landing");
      if (token == null) {
        token = localStorage.getItem("token-landing");
      }
      window.location.href = this.$mialta_url + "/landing/" + token;
    },
    checkUser() {
      if (
        this.$usuario.rolClientes != null &&
        this.$usuario.rolClientes.detalle == "Distribuidor"
      ) {
        this.title = "Modifica tu perfil de cliente BASF cuando lo desees";
        this.description =
          "Podrás editar o generar nuevas direcciones de entrega, actualizar tu información de contacto y más.";
        this.show = true;
      } else {
        this.getClients();
      }
    },
    getClients() {
      const that = this;
      this.$axios
        .get(this.$localurl + "/api/clientes")
        .then(function(response) {
          console.log(response);
          if (response.data != null && response.data.length > 0) {
            that.title =
              "¡Algunos clientes están esperando tu aprobación de alta!";
            that.description =
              "Chekealos cuanto antes para que puedan seguir su proceso de revisión.";
            that.show = true;
          } else {
            that.show = false;
          }
        })
        .catch(function(response) {
          that.show = false;
          console.log(response);
        });
    }
  }
};
</script>
<template>
  <div
    v-if="show"
    v-infocus="'showElement'"
    class="hidden hidden-down anuncio-container "
  >
    <img
      class="icon_lg circular-img"
      src="../../assets/img/icons/green-person.svg"
      alt="profile image"
    />
    <div class="anuncio-info">
      <div class="anuncio-info__header">
        <h2>
          {{ title }}
        </h2>
      </div>
      <p>
        {{ description }}
      </p>
      <a @click="ir()">Leer mas</a>
    </div>
  </div>
</template>
<style src="../../assets/css/components/modify-your-profile-card.css"></style>
