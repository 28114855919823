import { render, staticRenderFns } from "./Landing-new.vue?vue&type=template&id=2138fe2b&scoped=true"
import script from "./Landing-new.vue?vue&type=script&lang=js"
export * from "./Landing-new.vue?vue&type=script&lang=js"
import style0 from "../assets/css/app.css?vue&type=style&index=0&id=2138fe2b&prod&scoped=true&lang=css&external"
import style1 from "../assets/css/views/landing-new.css?vue&type=style&index=1&id=2138fe2b&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2138fe2b",
  null
  
)

export default component.exports