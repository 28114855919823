<template>
  <div class="academia-container">
    <img
      class="academy-image"
      src="https://images.unsplash.com/photo-1501504905252-473c47e087f8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80"
      alt="course thumbnail"
    />
    <div class="academia-info">
      <h2>{{ curso.title }}</h2>
      <p>{{ curso.descripcion }}</p>
      <div class="academia-buttons-container">
        <button class="blue-outline-btn">
          Material
          <img
            class="icon_default"
            src="../assets/img/icons/dowload_blue.png"
            alt=""
          />
        </button>
        <button class="blue-solid-btn">
          Ver video<img
            class="icon_default"
            src="../assets/img/icons/play_white.png"
            alt=""
          />
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    curso: Object
  }
};
</script>
<style scoped src="../assets/css/components/academia.css"></style>
